import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import SpacerSection from "../../../V2/Layouts/SpacerSection"

export default function StoryblokSpacerSection({
  blok,
}: Storyblok.BlokProps<Storyblok.SpacerSection>) {
  return (
    <SpacerSection
      backgroundColor={blok.backgroundColor}
      height={blok.height}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
